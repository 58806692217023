import React, { Component } from "react"

class Col extends Component {
  static defaultProps = {
    size: "half",
  }

  render() {
    return (
      <div className={"col col-" + this.props.size} {...this.props}>
        {this.props.children}
      </div>
    )
  }
}

export { Col }
