import React from "react"

const VideoEmbed = ({ youtubeId, vimeoId }) => {
  const src = youtubeId
    ? `https://www.youtube.com/embed/${youtubeId}`
    : `https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&color=f05e2b`

  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        title="video"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={src}
        frameBorder="0"
        allow="fullscreen;"
      />
    </div>
  )
}

export { VideoEmbed }
