import { Link } from "gatsby"
import React, { Component } from "react"

import { Group, Col } from "./common"

class Header extends Component {
  static defaultProps = {
    darkHeader: false,
    wide: false,
  }

  state = {
    opaque: false,
    headerLinks: [
      { url: "/", title: "Home" },
      { url: "/about", title: "About" },
      { url: "/s-factor", title: "Solution" },
      { url: "/analytics", title: "Analytics" },
      { url: "/news", title: "Media" },
      { url: "/contact", title: "Contact" },
    ],
  }

  componentDidMount() {
    if (this.props.opaque) {
      this.setState({ opaque: true })
    }
    window.addEventListener("scroll", this.handleScroll.bind(this))
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this))
  }

  handleScroll(event) {
    const scrollTop = window.scrollY
    const opaque = scrollTop > 100

    this.setState({
      opaque: opaque,
    })
  }

  renderNav() {
    return this.state.headerLinks.map(link => {
      return (
        <li>
          <Link to={link.url} activeClassName="active">
            {link.title}
          </Link>
        </li>
      )
    })
  }

  render() {
    let className = ""
    if (this.state.opaque) {
      className += "opaque "
    }

    if (this.props.darkHeader) {
      className += "dark "
    }

    return (
      <header className={className}>
        <div className={this.props.wide ? "wrap wrap-wide" : "wrap"}>
          <Group>
            <Col>
              <Link to="/">
                <div className="logo">
                  <h1>{this.props.siteTitle}</h1>
                </div>
              </Link>
            </Col>
            <Col>
              <ul>{this.renderNav()}</ul>
            </Col>
          </Group>
        </div>
      </header>
    )
  }
}

export default Header
