import React, { Component } from "react"

class Section extends Component {
  static defaultProps = {
    hasDivider: true,
    dark: false,
    lessPadding: false,
    wide: false,
  }

  renderDivider() {
    if (this.props.hasDivider) {
      return (
        <div className={this.props.wide ? "wrap wrap-wide" : "wrap"}>
          <div
            className="divider"
            style={this.props.lessPadding ? { marginTop: 2 } : {}}
          />
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <section
        className={this.props.dark ? "dark" : "light"}
        style={
          this.props.lessPadding ? { paddingTop: 12, paddingBottom: 8 } : {}
        }
      >
        <div className={this.props.wide ? "wrap wrap-wide" : "wrap"}>
          {this.props.children}
        </div>
        {this.renderDivider()}
      </section>
    )
  }
}

export { Section }
