function chunkArray(input, count) {
  const results = []
  let columnIndex = 0

  for (let i = 0; i < count; i++) {
    results[i] = []
  }

  for (let i = 0; i < input.length; i++) {
    results[columnIndex].push(input[i])
    columnIndex++
    if (columnIndex >= count) {
      columnIndex = 0
    }
  }

  return results
}

function splitArray(arr, bulkSize = 20) {
  const bulks = []
  for (let i = 0; i < Math.ceil(arr.length / bulkSize); i++) {
    bulks.push(arr.slice(i * bulkSize, (i + 1) * bulkSize))
  }
  return bulks
}

export { chunkArray, splitArray }
