import { Link } from "gatsby"
import React, { Component } from "react"

class CtaButton extends Component {
  static defaultProps = {
    linkTo: "#",
    target: "_self",
    href: null,
    dark: false,
  }

  render() {
    if (this.props.href) {
      return (
        <a
          href={this.props.href}
          target={this.props.target}
          className={["cta-link", this.props.dark ? "cta-link-dark" : ""].join(
            " "
          )}
        >
          <div className="chevron-circle" />
          <span>{this.props.children}</span>
        </a>
      )
    }

    if (this.props.onClick) {
      return (
        <a
          onClick={this.props.onClick}
          href="javascript:void"
          target={this.props.target}
          className={["cta-link", this.props.dark ? "cta-link-dark" : ""].join(
            " "
          )}
        >
          <div className="chevron-circle" />
          <span>{this.props.children}</span>
        </a>
      )
    }

    return (
      <Link
        className={["cta-link", this.props.dark ? "cta-link-dark" : ""].join(
          " "
        )}
        to={this.props.linkTo}
      >
        <div className="chevron-circle" />
        <span>{this.props.children}</span>
      </Link>
    )
  }
}

export { CtaButton }
