import React from "react"

class FeedItem extends React.Component {
  render() {
    if (!this.props.article) {
      return null
    }

    const { article, renderTop } = this.props

    return (
      <div
        className={`feed-item ${this.props.dark ? "feed-item--dark" : null}`}
      >
        <a href={article.website_url} target="_blank" rel="noopener noreferrer">
          {renderTop ? (
            <div className="top">
              <div
                className={
                  article.feed.image ? "feed-avatar" : "feed-avatar-no-image"
                }
              >
                <img src={article.feed.image} alt="pic" />
              </div>
              <div className="feed-name">{article.feed.name}</div>
            </div>
          ) : null}
          <div
            className={article.image_url ? "image" : "no-image"}
            style={{ backgroundImage: "url(" + article.image_url + ")" }}
          >
            <div className="title">{article.title}</div>
          </div>
          <div className="excerpt">{article.excerpt}</div>
          <div className="read-more">
            <a
              href={article.website_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </a>
      </div>
    )
  }
}

export { FeedItem }
