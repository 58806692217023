import React, { Component } from "react"

import logoDark from "../images/logo-dark.svg"
import { Group, Col } from "./common"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

import award2 from "../images/awards/awards-02.png"
import award3 from "../images/awards/awards-03.png"
// import award4 from "../images/awards/awards-04.png"
import award1 from "../images/awards/awards-01.png"
import award6 from "../images/awards/awards-06.png"
import award7 from "../images/awards/awards-07.png"

import award8 from "../images/awards/ubs.jpg"
import award9 from "../images/awards/aifintech.jpg"
import award10 from "../images/awards/futurology.jpg"
import award11 from "../images/awards/daily-finance.jpg"
import award12 from "../images/awards/best-startups-canada.jpg"
import award13 from "../images/awards/it-world-canada.jpg"
import award14 from "../images/awards/private-asset-mgmt.jpg"
import award15 from "../images/awards/wp.jpg"
import award16 from "../images/awards/ubs-ff.png"

import { chunkArray, splitArray } from "../utils"

class Footer extends Component {
  state = {
    footerLinks: [
      {
        url:
          "https://visitor.r20.constantcontact.com/d.jsp?llr=8qhp7l4ab&p=oi&m=8qhp7l4ab&sit=5l9yrlunb&f=cebffb97-eef1-4cc1-bc3a-e4b16d3783bd",
        title: "Newsletter",
        external: true,
      },
      { url: "/careers", title: "Careers" },
      { url: "/contact", title: "Contact" },
      { url: "/privacy", title: "Privacy Policy" },
    ],
    awards: [
      {
        img: award16,
        url:
          "https://www.ubs.com/microsites/future-of-finance-challenge/en.html",
        name: "Future of Finance Challenge",
        provider: "UBS",
        year: "2021",
      },
      // {
      //   img: award8,
      //   url:
      //     "https://www.ubs.com/microsites/future-of-finance-challenge/en.html",
      //   name: "Future of Finance Challenge",
      //   provider: "UBS",
      //   year: "2021",
      // },
      {
        img: award9,
        url: "https://fintech.global/aifintech100/",
        name: "AIFINTECH100",
        provider: "Fintech Global",
        year: "2021",
      },
      {
        img: award10,
        name: "36 Most Innovative",
        provider: "Futurology",
        year: "2021",
        url:
          "https://futurology.life/36-most-innovative-toronto-based-predictive-analytics-companies/",
      },
      // {
      //   img: award11,
      //   url: "https://df.media/97-leading-ontario-risk-management-firms-and-startups-the-future-of-risk-management/",
      // },
      {
        img: award11,
        name: "43 Leading Risk Management",
        provider: "Daily Finance",
        year: "2021",
        url:
          "https://df.media/43-leading-toronto-risk-management-firms-and-startups-the-future-of-risk-management/",
      },
      {
        img: award12,
        name: "14 Top Social Impact Startups",
        provider: "Best Startup Canada",
        year: "2021",
        url:
          "https://beststartup.ca/14-top-social-impact-startups-and-companies-in-canada/",
      },
      {
        img: award13,
        name: "Top Women in FinTech",
        provider: "IT World Canada",
        year: "2021",
        url:
          "https://www.itworldcanada.com/canadas-top-women-in-fintech-blockchain",
      },
      {
        img: award14,
        name: "PAM Awards",
        provider: "PAM",
        year: "2021",
        url: "https://pamawards.awardstage.com/#!/p1",
      },

      // {
      //   img: award7,
      //   url: "https://pamawards.awardstage.com/",
      //   name: "",
      //   provider: "",
      //   year: "",
      // },
      {
        img: award2,
        name: "Women of Influence",
        provider: "womenofinfluence.ca",
        year: "2019",
        url: "https://www.womenofinfluence.ca/",
      },
      {
        img: award6,
        name: "TIE 50",
        provider: "TIE",
        year: "2019",
        url: "https://sv.tie.org/programs/tie50/",
      },
      // {
      //   img: award1,
      //   name: "",
      //   provider: "",
      //   year: "",
      //   url:
      //     "https://www.wealthprofessional.ca/news/industry-news/women-in-wealth-management-award-winners-announced/250958",
      // },

      {
        img: award1,
        name: "Excellence in Philanthropy & CSR",
        provider: "Wealth Professional",
        year: "2018",
        url:
          "https://www.wealthprofessional.ca/news/industry-news/women-in-wealth-management-award-winners-announced/250958",
      },
      {
        img: award3,
        name: "I-3 Zone and Sector",
        provider: "innovacorp",
        year: "2016",
        url: "https://innovacorp.ca/news/i-3-zone-and-sector-winners-2015-2016",
      },
    ],
  }

  renderNav() {
    return this.state.footerLinks.map(link => {
      if (link.external) {
        return (
          <li>
            <a href={link.url} target="_blank">
              {link.title}
            </a>
          </li>
        )
      }
      return (
        <li>
          <Link to={link.url} target={link.target}>
            {link.title}
          </Link>
        </li>
      )
    })
  }

  renderAwardsSlider() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
    }

    return <Slider {...settings}>{this.renderAwards()}</Slider>
  }

  renderAwards() {
    const awards = splitArray(this.state.awards, 5)
    return awards.map((chunk, i) => {
      return (
        <div className="footer-partners" key={i}>
          {chunk.map((p, j) => {
            return (
              <div class="partner-container">
                <a
                  key={`${i}-${j}`}
                  href={p.url}
                  className="partner"
                  target="_blank"
                >
                  <div className="star"></div>

                  <img src={p.img} />
                </a>
                <div className="label">
                  <div>{p.name}</div>
                  <div>
                    {p.provider} {p.year && p.provider ? "|" : ""} {p.year}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    })
  }

  render() {
    return (
      <footer>
        <div className="wrap">
          <Group>
            <Col size="third">
              <img src={logoDark} width="128" alt="s-factor" />
              <div className="footer-info">
                <p>
                  401 Bay Street Suite 2702
                  <br />
                  Toronto, Ontario
                  <br />
                  M5H 2Y4
                </p>
                <p>
                  Toronto +1 647 930 9075
                  <br />
                  Toll Free +1 877 276 4822
                  <br />
                  Email info@thesfactor.co
                  <br />
                </p>
                <p className="copyright">
                  &copy;2021 The S Factor Co.<sup>TM</sup>
                </p>
              </div>
            </Col>
            <Col size="two-thirds">
              <h6>Awards</h6>
              <div>{this.renderAwardsSlider()}</div>
              <ul>{this.renderNav()}</ul>
            </Col>
            {/* <Col size="half">
            </Col> */}
          </Group>
        </div>
      </footer>
    )
  }
}

export default Footer
